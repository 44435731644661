<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('bulk_import')" :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('bulk_import')" :isFilter="false"/>
            </template>
            <h6>{{ $t('process_select') }}</h6>
            <div class="border rounded p-5 mb-3">
                <multiselect
                    :clear-on-select="true"
                    :close-on-select="true"
                    :deselect-label="''"
                    :options="computeReportTypes"
                    :placeholder="$t('select')"
                    :select-label="''"
                    :selected-label="''"
                    label="text"
                    track-by="value"
                    v-model="reportType"
                />
            </div>
            <b-row>
                <div class="col-12" v-if="reportType">
                    <h6>
                    <span v-if="reportType && reportType.text">
                        {{ reportType.text }}
                    </span>
                    </h6>
                    <b-row>
                        <b-col cols="12">
                            <div class="border rounded p-5">
                                <component v-if="reportType && reportType.value" :is="reportType.value"/>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-row>
        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
// Data
import ReportTypes from '../data/ReportTypes'
// Pages
import makeUpExam from './makeUpExam/Index.vue'
import studentCourse from './studentCourse/Index.vue'
import studentCourseDelete from './studentCourseDelete/Index'
import studentSemesterStatusUpdate from './studentSemesterStatusUpdate/Index'
import bulkStudentSlotUpdate from './bulkStudentSlotUpdate/Index.vue'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        makeUpExam,
        studentCourse,
        studentCourseDelete,
        studentSemesterStatusUpdate,
        bulkStudentSlotUpdate

    },
    metaInfo() {
        return {
            title: this.$t('bulk_import')
        }
    },
    data() {
        return {
            reportType: null
        }
    },
    computed: {
        computeReportTypes() {
            return ReportTypes().filter((item) => {
                return this.checkPermission(item.permission)
            })
        }
    }
}
</script>
